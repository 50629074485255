
@import 'defs';

.invoice-rows {
	&__transparent-form-control {
		border-color: transparent;
		background-color: transparent;
	}

	#total,
	#balance,
	#refund {
		border: 1px solid black;
	}

	.p-outline {
		border: 1px solid black;
	}

	.cell-container,
	.left-edge,
	.right-edge {
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.p-invoice-paid {
		background-color: lightgreen;
	}
	.p-invoice-unpaid {
		background-color: lightyellow;
	}
}