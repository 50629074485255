// https://stackoverflow.com/questions/9862167/positioning-div-element-at-center-of-screen/22278778#22278778

.image-box {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	// border: 1px solid black;
}

@media screen and (min-aspect-ratio: 1.0) {
	.company-image {
		height: 85vh;
	}
}

@media screen and (max-aspect-ratio: 1.0) {
	.company-image {
		width: 90vw;
	}
}
