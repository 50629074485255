
@import 'defs';

body {
	background-color: $c-background;
}

.p-alert-line {
	margin-bottom: 0rem;
}

.p-button {
	height: 1rem;
	width: 1rem;
	cursor: pointer;
}

// Footer for all pages, designed to provide some scroll space at the bottom of the page.
.page-bottom-padding {
	margin-bottom: 12rem;
}

.form-page {
	&--modified.card {
		background-color: $c-modified;
	}
}

.half-card-body-padding-top {
	padding-top: 0.625rem;
}

.no-top-border {
	border-top: none;
}

// This is used rather than Bootstrap's "d-none", because "d-none" includes !important,
// which overrides jQuery's show and hide functions.
.p-hidden {
	display: none;	
}

.picker-box {
	cursor: pointer;
	background-color: #fff !important;
}

.danger-shadow-box {
	border-color: #FF0000;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 12px rgba(255, 0, 0, 0.6);
}

.employee-picker {
	.employee-invalid {
		color: red;
	}
}

.card-body {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 12px $c-border-color;
}

.p-pointer-cursor {
	cursor: pointer;
}

.form-group {
	margin-bottom: 1rem;
}

.form-inline .form-control {
	display: inline-block;
	width: auto;
	vertical-align: middle;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.form-row > .col {
	padding-left: 5px;
	padding-right: 5px;
}

label {
	margin-bottom: 0.5rem;
}

.static-view-cell {
	background-color: rgb(233, 236, 239); // $input-disabled-bg from bootstrap.
}