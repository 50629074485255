
.login-container {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.login-image {
	width: 100%;
}

.image-col, .controls-col {
	padding-right: 15px;
	padding-left: 15px;
}

.bounding-col {
	flex: 0 0 80%;
	max-width: 80%;
}

.image-col, .controls-col {
	flex: 0 0 100%;
	max-width: 100%;
}

@media screen and (min-aspect-ratio: 0.9) {
	.image-col {
		flex: 0 0 45%;
		max-width: 45%;
	}
	.controls-col {
		flex: 0 0 55%;
		max-width: 55%;
	}
}
